// common application constants
import testShot from './../images/testShot.jpg';

export const DESIGNS = [
  { name: 'Modern Garden', image: testShot }, //pineWatt
  { name: 'Traditional Landscape', image: testShot },
  { name: 'Zen Retreat', image: testShot }, //'zen-retreat.jpg'
  { name: 'Cottage Garden', image: testShot },
  { name: 'Desert Oasis', image: testShot },
  { name: 'Tropical Paradise', image: testShot },
  { name: 'English Country Garden', image: testShot },
  { name: 'Mediterranean Courtyard', image: testShot },
  { name: 'Asian Zen Garden', image: testShot },
  { name: 'Coastal Escape', image: testShot },
  { name: 'Urban Rooftop Garden', image: testShot },
  { name: 'Wildflower Meadow', image: testShot },
  { name: 'Formal Courtyard', image: testShot },
  { name: 'Xeriscape Garden', image: testShot },
];

// export const API_ROOT = 'https://api.enviromentai.com';
// export const API_ROOT = 'http://localhost:3000';

export const API_ROOT = process.env.NODE_ENV === 'production'
  ? 'https://api.enviromentai.com'
  : 'http://localhost:3000';

export const VARS = {
  // apiPath: 'https://firstkiss.app/api/',
  apiPath: 'http://localhost:4000/api/',
  apiRoot: 'http://localhost:4000/',
}


// export const API_WS_ROOT = 'ws://localhost:4000/cable';
export const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const COLORS = {
  white: '#fff',
  black: '#000',
  // blue: 'blue',
  // your colors
  darkGreen: '#004550',
  compliment: '#02828d', //bahama blue

  lightGreen: '#42B491', //this is actually light blue
  green: '#3DA2AE', //this is turquiose not green
  turquiose: '#3DA2AE', // lighter bahama blue
  beige: '#DAD09D',
  orange: '#EBA31D',
  red: '#D94929',
  errorRed: '#f71b1b',

  midnightBlue: '#0d1b2a',
  darkBlue: '#1b263b',
  blue: '#415a77',
  lightBlue: '#778da9',
  cloudy: '#e0e1dd'
}

export const DCOLORS = {
  // Primary colors
  background: '#121212',    // Dark background
  surface: '#1E1E1E',       // Slightly lighter surface
  primary: '#BB86FC',       // Primary color for buttons, links, etc.
  primaryVariant: '#3700B3', // Darker variant of the primary color

  // Secondary colors
  secondary: '#03DAC6',      // Accent color
  secondaryVariant: '#018786', // Darker variant of the secondary color

  // Text colors
  textPrimary: '#FFFFFF',    // Primary text color
  textSecondary: '#B0B0B0',  // Secondary text color

  // Error colors
  error: '#CF6679',          // Error color

  // Other colors
  divider: '#292929',        // Color for dividers or borders
  backgroundAlt: '#181818',  // Alternate background color for contrast

  // Custom colors
  darkGreen: '#004550',      // Dark green
  compliment: '#02828d',     // Bahama blue
  lightGreen: '#42B491',     // Light blue
  green: '#3DA2AE',          // Turquoise
  turquiose: '#3DA2AE',      // Lighter bahama blue
  beige: '#DAD09D',          // Beige
  orange: '#EBA31D',         // Orange
  red: '#D94929',            // Red
  errorRed: '#f71b1b',       // Error red
  midnightBlue: '#0d1b2a',   // Midnight blue
  darkBlue: '#1b263b',       // Dark blue
  blue: '#415a77',           // Blue
  lightBlue: '#778da9',      // Light blue
  cloudy: '#e0e1dd'          // Cloudy
};

export const P1 = {
  // red: '#be3144', //red
  // black: '#303841', //black
  // gray: '#3a4750', //gray
  // white: '#d3d6db', //white
  a: '#be3144', //red rgb(190,49,68)
  b: '#303841', //black rgb(48,56,65)
  c: '#3a4750', //gray rgb(58,71,80)
  d: '#d3d6db', //white rgb(211,214,219)
}

export const P2 = {
  a: '#f67280', //pink
  b: '#35477d', //blue
  c: '#6c5b7b', //purple
  d: '#c06c84', //muted pink
}

export const TEST = {
  apiURL: 'http://localhost:3000',
  // Stripe developer API Secret Key, used in Subscriptions 
  stripeClientSecret: 'sk_test_51Nds8hEQV7VZRFF8FimtCVZoWs4qsn6uT4QDxFuwQDYRoizYpFSViCITMsKAjkfEba3vtxsHuAVrkewrfKbBCsuA00RcGYoGkb',
  // Stripe developer API Publishable Key, used in Subscriptions 
  stripePublishableKey: 'pk_test_51Nds8hEQV7VZRFF8uJ5kxSrbfBw5AUt7fJztw7eJInp8BhFPjcYcf0DAR870d2vEd5uKE1eyN82rFeG26M0EyOFa00MnDktmzE',
  // url https://api.enviromentai.com/api/v1/stripe-webhook , used in rails only?
  stripeWebhookSigningSecret: 'whsec_0YSDn9ywltnC6xjO0iVjc6KhViyDRo99',
}

export const PROD = {
  apiURL: 'https://api.enviromentai.com',
  // Stripe developer API Secret Key
  stripeClientSecret: 'pk_live_51Nds8hEQV7VZRFF8PzmqeQhRdWsF3PON4vb90JqUv8VVs2nwQ4HTpucTrJsASsaauKImSfFrkhsryQTKF3kY7Wrn00DEOQjncn',
  // Stripe developer API Publishable Key
  // stripePublishableKey: ''
  // stripeWebhookSigningSecret:/
}


