// CartContext.js
import React, { createContext, useState, useEffect } from 'react';
import { addItemToCart, removeItemFromCart, getCartItems, clearCart } from './localStorageUtils';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    setCart(getCartItems());
  }, []);

  const addToCart = (item) => {
    addItemToCart(item);
    setCart(getCartItems());
  };

  const removeFromCart = (itemId) => {
    removeItemFromCart(itemId);
    setCart(getCartItems());
  };

  const clearCartItems = () => {
    clearCart();
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCartItems }}>
      {children}
    </CartContext.Provider>
  );
};
