// Utils
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, useNavigate } from "react-router-dom";
import {DESIGNS} from './js/Constants.js';

// api Calls
import PostUser from './js/api/v1/PostUser';
import PostEmail from './js/api/v1/PostEmail';
import Post from './js/api/v1/Post'
import Get from './js/api/v1/Get'

// Sub Views
import MainFeed from './js/MainFeed'; // Import the MainFeed component
import LandscapeGallery from './LandscapeGallery'; // Import the LandscapeGallery component
import EmailPopup from './EmailPopup'; // Make sure to import the Popup component
import LoginForm from './js/subViews/LoginForm'; // Make sure to import the Popup component

import Cart from './js/screens/Cart';
import Gallery from './js/subViews/Gallery'; // Import the Gallery component

// css
import './styles.css';
import './LandscapeGallery.css'; // Import the CSS file for LandscapeGallery
import { COLORS } from './js/Constants'; // Import the MainFeed component
import { DCOLORS } from './js/Constants'; // Import the MainFeed component


function HomePage() {
  const [email, setEmail] = useState('');
  const [showInvalidEmailPopup, setShowInvalidEmailPopup] = useState(false);
  const handleEmailChange = (e) => {  // console.log("setting email: ",e);  // console.log("setting email to: ",email); 
    setEmail(e.target.value);  };

  const [submittedEmail, setSubmittedEmail] = useState('');
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const navigate = useNavigate()

  const handleLoginSuccess = () => {  // Redirect to another page after successful login  // console.log("handle login success");
    navigate('/home')
  };
  const handleSignupSuccess = () => { setShowCreatedUserPopup(true);  }

  const [showCreatedUserPopup, setShowCreatedUserPopup] = useState(false);
  const [selectedDesign, setSelectedDesign] = useState(null);

  const handleCloseInvalidEmailPopup = () => { setShowInvalidEmailPopup(false);   };
  const handleShowCreatedUserPopup = () => {  setShowCreatedUserPopup(false);   }

  // handle design scroll selection for landscape gallery
  const handleSelectDesign = (design) => {    setSelectedDesign(design);  };

  const sampleImages = [
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 1' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 2' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 3' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 4' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 5' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 6' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 7' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 8' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 9' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 10' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 11' },
    { src: 'https://via.placeholder.com/300', alt: 'Sample Image 12' },
    // Add more image objects as needed
  ];

  useEffect(() => {   // check for user session, if none, request login
    var session = localStorage.getItem('session_id');
    if (session !== null) {   navigate('/Home')   }
    return () => {  };
  }, [0]); 

  const handleSubmitEmail = (e) => {
    e.preventDefault(); 
    // check email for validity
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      // check if email exists as user in rails
      var emailJson =  JSON.stringify({ user: {email: email} })
      Post('users/email_exists', emailJson)
      .then((result) => {
        if(result) {
          // show password popup
          // console.log("user exists: ",email)
          setShowLoginPopup(true);
        } else {
          // send user creation request to rails, then signup email verification to user from api // console.log("valid email: ", email);
          PostEmail(JSON.stringify({"email": email, "password": '012345678910'}))
          .then((response) => {
            if (response.status) {
              return Promise.resolve(setShowCreatedUserPopup(true));
            }
        })
        }
      })
    } else {
      // Show the popup if the email is invalid
      setShowInvalidEmailPopup(true);
    }
  };




  return (
    <div className="homepage">
      <header 
        style={{ 
          backgroundColor: COLORS.midnightBlue, 
          color: COLORS.cloudy }}>
        <a className="home-link" href="/">PrintPaperAI</a>
        <nav>
          <a style={{ color: COLORS.cloudy }} href="#">Help</a>
          <a style={{ color: COLORS.cloudy }} href="/cart">Cart</a>
          <a style={{ color: COLORS.cloudy }} href="#">Sign In</a>
        </nav>
      </header>
      <div style={{display: 'flex', color: 'white', alignContent:'space-around', justifyContent:'space-around', width: '30%', marginTop:'10px'}}>
        <a style={{ color: COLORS.cloudy, textDecoration: 'none' }} href="/">Browse</a>
        <a style={{ color: COLORS.cloudy, textDecoration: 'none' }} href="/home"> Create</a>
      </div>
      <main style={{ backgroundColor: COLORS.darkBlue, color: COLORS.cloudy }}>
        <div style={{display: 'flex', width: '100%'}}>
          <div style={{ flex: 1, width:'50%', flexDirection:'column'}}>
            <div style={{ flex: 1}}>
              <h2>Create posters and stickers shipped to you, with or without a camera</h2>
            </div>
            <div style={{ flex: 1}}>
              <h5>Create stickers or posters in custom styles.</h5>
            </div>
          </div>
        </div>
        <Gallery images={sampleImages} />
      </main>
      <footer>
        <p>&copy; PrintPaperAI. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default HomePage;