import React from 'react';
import './css/Gallery.css'; // Importing the CSS file for styling

const Gallery = ({ images }) => {
  return (
    <div className="gallery-container">
      {images.length > 0 ? (
        images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image.src} alt={image.alt} className="gallery-image" />
          </div>
        ))
      ) : (
        <div className="no-images">
          <div className="gallery-item placeholder">No Image</div>
          <div className="gallery-item placeholder">No Image</div>
          <div className="gallery-item placeholder">No Image</div>
          <div className="gallery-item placeholder">No Image</div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
