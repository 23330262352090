// localStorageUtils.js

// Add item to local storage
export const addItemToCart = (item) => {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    cart.push(item);
    localStorage.setItem('cart', JSON.stringify(cart));
  };
  
  // Remove item from local storage
  export const removeItemFromCart = (itemId) => {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    cart = cart.filter(item => item.id !== itemId);
    localStorage.setItem('cart', JSON.stringify(cart));
  };
  
  // Get items from local storage
  export const getCartItems = () => {
    return JSON.parse(localStorage.getItem('cart')) || [];
  };
  
  // Clear cart
  export const clearCart = () => {
    localStorage.removeItem('cart');
  };
  