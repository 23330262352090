import React, { useState } from 'react';
import './css/PhotoHistory.css';
import { API_ROOT } from '../../Constants.js';

// note that the image shown is an image variant. show the true image in the modal..
const PhotoHistory = ({ images }) => {
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  const handleClick = (index) => {
    setSelectedPrompt(index);
  };

  const handleCloseModal = () => {
    setSelectedPrompt(null);
  };

  return (
    <div className="photo-history">
      <h5>History</h5>
      <ul className="prompt-list">
        {images.map((imageArr, index) => (
          <li
            key={index}
            className="prompt-item"
            title={imageArr[0].prompt}
            onClick={() => handleClick(index)}
          >
            {imageArr[0].prompt}
          </li>
        ))}
      </ul>
      {/* Render modal here */}
      {selectedPrompt !== null && (
        <Modal
            id={images[selectedPrompt][0].id}
            filename={images[selectedPrompt][0].filename}
            image={images[selectedPrompt][0].url}
            prompt={images[selectedPrompt][0].prompt}
            onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

// Modal component
const Modal = ({ id, filename, image, prompt, onClose }) => {
    // console.log("id: ", id)
    // console.log("image: ", image)
    // console.log("prompt: ", prompt)
    // console.log("filename: ", filename)

    const handleDownload = (id, filename) => {
        // console.log("handling download: ")
        // console.log("ID: ", id)
        var session = localStorage.getItem('session_id');
        const requestOptions = {
            headers: {
              Authorization: `Bearer ${session}`, // Include your authentication token here
            },
        };
        var url = 'generatedimgs/'+id+'/download'
        const imageUrl = url.includes('http') ? url : API_ROOT + '/api/v1/' + url;
        // console.log("requesting url:... ", imageUrl)
        fetch(imageUrl, requestOptions)
        // fetch(url)
          .then((response) => {
        //   console.log('Fetch response:', response);
          return response.blob() // Convert the response to a Blob
          }).then((blob) => {
            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob); // Create a local URL for the Blob
            link.download = filename; // Set the download attribute with the desired file name
            link.click(); // Simulate a click event to trigger the download
          })
          .catch((error) => {
            console.error('Error downloading image:', error);
          });
    };

  return (
    <div className="history-modal-overlay" onClick={onClose}>
      <div className="history-modal" onClick={(e) => e.stopPropagation()}>
        <button className='history-modal-close' onClick={onClose}>X</button>
        <img src={image} alt="Prompt Image" />
        <p>{prompt}</p>
        <button className="history-modal-download-button"
            onClick={() => handleDownload(id, filename)}
            > Download 
        </button>
      </div>
    </div>
  );
};

export default PhotoHistory;
