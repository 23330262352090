// CartScreen.js
import React, { useContext } from 'react';
import { CartContext } from '../utils/CartContext';
import '../../styles.css';
import { COLORS } from '../Constants'; // Import the MainFeed component
// import { DCOLORS } from '../js/Constants'; // Import the MainFeed component

const Cart = () => {
  const { cart, removeFromCart, clearCartItems } = useContext(CartContext);

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price, 0);
  };

  return (
    <div style={styles.container}>
      <header 
        style={{ 
          backgroundColor: COLORS.midnightBlue, 
          color: COLORS.cloudy }}>
        <a className="home-link" href="/">PrintPaperAI</a>
        <nav>
          <a style={{ color: COLORS.cloudy }} href="#">Help</a>
          <a style={{ color: COLORS.cloudy }} href="/cart">Cart</a>
          <a style={{ color: COLORS.cloudy }} href="#">Sign In</a>
        </nav>
      </header>
      <h1 style={styles.heading}>Your Cart</h1>
      {cart.length === 0 ? (
        <p style={styles.emptyCart}>Your cart is empty.</p>
      ) : (
        <div>
          {cart.map(item => (
            <div key={item.id} style={styles.cartItem}>
              <h3 style={styles.itemName}>{item.name}</h3>
              <p style={styles.itemPrice}>${item.price}</p>
              <button style={styles.removeButton} onClick={() => removeFromCart(item.id)}>
                Remove
              </button>
            </div>
          ))}
          <div style={styles.total}>
            <h2>Total: ${calculateTotal()}</h2>
            <button style={styles.clearButton} onClick={clearCartItems}>
              Clear Cart
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  h1: {
    color: '#e0e1dd'
  },
  container: {
    // padding: '20px',
    // maxWidth: '800px',
    margin: '0 auto',
    // backgroundColor: '#f4f4f4',
    backgroundColor: '#1b263b',
    borderRadius: '8px'
  },
  heading: {
    marginTop: '20px',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#e0e1dd'
  },
  emptyCart: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#e0e1dd'
  },
  cartItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #ccc'
  },
  itemName: {
    fontSize: '20px'
  },
  itemPrice: {
    fontSize: '18px',
    color: '#555'
  },
  removeButton: {
    backgroundColor: '#ff4d4d',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    cursor: 'pointer',
    borderRadius: '4px'
  },
  total: {
    textAlign: 'center',
    marginTop: '20px'
  },
  clearButton: {
    backgroundColor: '#ff4d4d',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '4px'
  }
};

export default Cart;
