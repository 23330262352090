// import logo from './logo.svg';
import './App.css';
import React from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Homepage';
import Plans from './js/screens/Plans';
import SinglePaymentOptions from './js/screens/SinglePaymentOptions';
import Home from './js/screens/Home';
import Cart from './js/screens/Cart';
import { CartProvider } from './js/utils/CartContext';

import Authenticated from './js/screens/Authenticated';
import Status from './js/screens/Status';

// import ReactGA from "react-ga4";
// const MEASUREMENT_ID = "G-S5TZZ5P863"; // OUR_TRACKING_ID
// ReactGA.initialize("G-S5TZZ5P863");
// ReactGA.send("pageview");

function App() {

  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/plans" element={<Plans />} />
          <Route exact path="/Home" element={<Home />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/options" element={<SinglePaymentOptions />} />
          <Route exact path="/status" element={<Status />} />
          {/* <Route path="/authenticated" element={<Authenticated />} /> */}
          {/* <Route path="/testpage" element={<TestPage />} /> */}
        </Routes>
      </Router>
    </CartProvider>

  );
  
}

export default App;
